/* eslint-disable react/no-unstable-nested-components */
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changePassword } from '@/common/reducers/meReducer'
import type IUser from '@/common/types/user.type'
import type { RootState } from '@/store'

const SettingsChangePassword: FC = () => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const authUser: IUser = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState(false)

  const onFinish = async (formData: any) => {
    if (formData.newPassword !== formData.confirmPassword) {
      messageApi.open({
        type: 'error',
        content: 'The new password and the confirmation password do not match.'
      })
      return
    }

    try {
      setLoading(true)
      await dispatch(
        changePassword({
          password: formData.password,
          newPassword: formData.newPassword
        })
      ).unwrap()

      form.resetFields()

      messageApi.open({
        type: 'success',
        content: 'Password updated successfully.'
      })
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    } finally {
      setLoading(false)
    }
  }

  if (!authUser) {
    return <div />
  }

  return (
    <div>
      {contextHolder}

      <div className='max-w-lg'>
        <Form form={form} className='' onFinish={onFinish} layout='vertical' autoComplete='off'>
          {!authUser.unsetPassword && (
            <Form.Item
              label='Password'
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input current password!'
                }
              ]}
            >
              <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
          )}

          <Form.Item
            label='New Password'
            name='newPassword'
            rules={[
              {
                required: true,
                message: 'Please input new password!'
              }
            ]}
          >
            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item label='Confirm Password' name='confirmPassword' rules={[{ required: true, message: 'Please confirm password!' }]}>
            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item className='mt-10'>
            <Button type='primary' htmlType='submit' loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default SettingsChangePassword
