import type { ReactNode } from 'react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  name: string
  to: string
  icon?: ReactNode
  className?: string
}

const MenuItem = ({ name, to, icon, className }: Props) => {
  const location = useLocation()

  return (
    <Link
      to={to}
      className={`flex cursor-pointer select-none flex-row items-center gap-1.5 rounded-t px-3 py-2 text-base transition duration-75 ease-in ${
        location.pathname === to ? 'bg-light-1' : 'hover:bg-gray-50'
      } ${className}`}
    >
      {icon}

      <div className='line-clamp-1'>{name}</div>
    </Link>
  )
}

export default MenuItem
