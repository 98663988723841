import React from 'react'

import InvoiceDetail from './InvoiceDetail'
import InvoicesIndex from './InvoicesIndex'
import InvoicesLayout from './layouts/InvoicesLayout'

export const InvoicesRoutes = [
  {
    path: 'invoices',
    element: <InvoicesLayout />,
    children: [
      { path: '', element: <InvoicesIndex /> },
      { path: ':id', element: <InvoiceDetail /> }
    ]
  }
]
