import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'

import api from '@/common/api'
import type { RootState } from '@/store'

import { EInvoiceStatus } from '../common/enum'

interface IState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    search?: string
    createdAt?: string
    status?: string
  }
  meta?: {
    paidCount?: number
    unpaidCount?: number
    processingCount?: number
    canceledCount?: number
    totalCount?: number
  }
}

const initialState: IState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 20,
    sorts: '-createdAt',
    createdAt: '',
    search: '',
    status: `in:${EInvoiceStatus.PROCESSING},${EInvoiceStatus.UNPAID}`
  },
  meta: {
    paidCount: 0,
    unpaidCount: 0,
    processingCount: 0,
    canceledCount: 0,
    totalCount: 0
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchInvoices = createAsyncThunk('invoice/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = { ...state.invoice.filter }

    if (!params.createdAt) {
      delete params.createdAt
    } else if (params.createdAt === 'today') {
      const now = new Date()
      const start = startOfDay(now).toISOString()
      const end = endOfDay(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'week') {
      const now = new Date()
      const start = startOfWeek(now).toISOString()
      const end = endOfWeek(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'month') {
      const now = new Date()
      const start = startOfMonth(now).toISOString()
      const end = endOfMonth(now).toISOString()

      params.createdAt = `range:${start},${end}`
    } else if (params.createdAt === 'year') {
      const now = new Date()
      const start = startOfYear(now).toISOString()
      const end = endOfYear(now).toISOString()

      params.createdAt = `range:${start},${end}`
    }

    if (params.search === '') {
      delete params.search
    }

    return await api.get('admin/invoices', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminInvoiceGetDto = {
  invoiceId: string
}

export const getInvoice = createAsyncThunk('invoice/get', async (payload: AdminInvoiceGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`admin/invoices/${payload.invoiceId}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type AdminInvoiceUpdateDto = {
  id: string
  name: string
}

export const updateInvoice = createAsyncThunk('invoice/update', async (payload: AdminInvoiceUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`admin/invoices/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AdminInvoiceDeleteDto = {
  id: string
}

export const deleteInvoice = createAsyncThunk('invoice/delete', async (payload: AdminInvoiceDeleteDto, { rejectWithValue }) => {
  try {
    await api.get(`admin/invoices/${payload.id}`)
    return { id: payload.id }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const invoiceSlice = createSlice({
  name: 'zobite_admin_invoice',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },
    updateLocal(state, { payload }) {
      const info = payload as any

      const index = state.items.findIndex((item) => item.id === info.id)

      if (index >= 0) {
        const newObject = Object.assign(state.items[index], info)
        state.items.splice(index, 1, newObject)
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        const { total, items, meta } = action.payload as any

        state.items = items
        state.total = total
        state.meta = meta
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        const info = action.payload as any

        const index = state.items.findIndex((item) => item.id === info.id)

        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        }
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { updateFilter, updateLocal } = invoiceSlice.actions
export default invoiceSlice.reducer
