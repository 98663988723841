import { UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import React from 'react'
import { BsPersonBadge } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import type { RootState } from '@/store'

import MenuItem from '../components/MenuItem'

const SettingsLayout = () => {
  const user = useSelector((state: RootState) => state.auth.user)

  return (
    <main>
      <div className='container flex flex-col border-b bg-white'>
        <div className='flex h-20 flex-row items-center'>
          <div className='flex flex-row items-center gap-4'>
            <Avatar size={40} style={{ backgroundColor: '#c6c6c6' }} icon={<UserOutlined />} />
            <div className='line-clamp-1 text-xl font-bold text-main'>{user.fullname}</div>
          </div>

          <div className='flex-1' />
        </div>

        <div className='flex flex-row gap-4'>
          <MenuItem to='/settings' name='Edit profile' icon={<BsPersonBadge size={15} />} />
        </div>
      </div>

      <div className='container flex-1 flex-col pt-8'>
        <Outlet />
      </div>
    </main>
  )
}

export default SettingsLayout
