import { Button, Form, Input, message, Select, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import api from '@/common/api'
import { getUpdatedDiff } from '@/common/utils'
import { convertEnumToSelectOptions } from '@/utils/converter'

import { EInvoiceStatus } from './common/enum'
import { getInvoice, updateInvoice, updateLocal } from './reducers/invoiceReducer'

const InvoiceDetail = () => {
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const [messageApi, contextHolder] = message.useMessage()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const fetchInfo = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(
          getInvoice({
            invoiceId: params.id
          })
        ).unwrap()

        setInfo(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      fetchInfo()
    }
  }, [params.id])

  useEffect(() => {
    if (errorMessage) {
      messageApi.error(errorMessage)
    }
  }, [errorMessage])

  const hideModal = async () => {
    // if (onClose) {
    //   onClose()
    //   setInfo({})
    // }
  }

  const handleApprove = async () => {
    try {
      setLoading(true)

      const rs: any = await api.put(`admin/invoices/${info.id}/approve`)

      if (rs?.id && rs?.status) {
        dispatch(updateLocal(rs))
        setInfo(rs)
      }
    } catch (error: any) {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      setErrorMessage(resMessage)
    } finally {
      setLoading(false)
    }
  }

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updatedInfo)) {
      hideModal()
      return
    }

    try {
      setLoading(true)
      await dispatch(
        updateInvoice({
          id: info.id,
          ...updatedInfo
        })
      ).unwrap()

      setInfo(Object.assign(info, updatedInfo))

      hideModal()
    } catch (error: any) {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      setErrorMessage(resMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(info, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <div>
      {contextHolder}
      {!loading && info?.id ? (
        <Form onValuesChange={handleValuesChange} layout='vertical' onFinish={onFinish} initialValues={info}>
          <div className='flex flex-row gap-6'>
            <Form.Item className='flex-1' label='InvoiceCode' name='code' required>
              <Input readOnly />
            </Form.Item>

            <Form.Item className='flex-1' label='Email' name='email' required>
              <Input readOnly />
            </Form.Item>
          </div>

          <Form.Item label='Status' name='status' required>
            <Select options={convertEnumToSelectOptions(EInvoiceStatus)} />
          </Form.Item>

          <div className='gap-4 center'>
            <Button disabled={disabled} loading={loading} className='mt-6' type='primary' htmlType='submit'>
              Update
            </Button>

            <Button className='mt-6' type='primary' onClick={handleApprove}>
              Approve
            </Button>
          </div>
        </Form>
      ) : (
        <div className='flex h-full flex-1 flex-col  center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default InvoiceDetail
