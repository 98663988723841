import React from 'react'

import SettingsLayout from './layouts/SettingsLayout'
import SettingsChangePassword from './SettingsChangePassword'
import SettingsIndex from './SettingsIndex'

export const SettingsRoutes = [
  {
    path: 'settings',
    element: <SettingsLayout />,
    children: [
      { path: '', element: <SettingsIndex /> },
      { path: 'change-password', element: <SettingsChangePassword /> }
    ]
  }
]
